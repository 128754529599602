<template>
  <div class="wrap">
    <Head />
    <div class="join-us">
      <div class="join-en">JOIN US</div>
      <div class="join-zh">有你而精彩</div>
      <div class="post">
        <div class="sociaty">
          <a
            href="https://app.mokahr.com/social-recruitment/gggames/46562#/"
          >社会招聘</a>
        </div>
        <div class="school">
          <a
            href="https://app.mokahr.com/campus-recruitment/gggames/45224#/"
          >校园招聘</a>
        </div>
      </div>
    </div>
    <!-- 为什么会喜欢这里 -->
    <div class="introduction">
      <div class="why">
        <p class="title">为什么你会喜欢这里</p>
        <div class="content">
          <p>宽敞舒适的办公环境，平等有爱的文化氛围</p>
          <p>品质为先，用心打磨，创造用户喜爱的产品</p>
          <p>突破自我，与业界资深大咖共创游戏未来</p>
        </div>
      </div>
      <div class="details">
        <ul>
          <li class="mr">
            <div>
              <img src="../../assets/image/mobile/index/icon1.png" alt="">
            </div>
            <div class="content">
              <p>具有行业竞争力的薪资和每年2次调薪机会！</p>
              <p>专家路线or管理路线发展晋升双通道！</p>
            </div>
          </li>
          <li>
            <div>
              <img src="../../assets/image/mobile/index/icon2.png" alt="">
            </div>
            <div class="content">
              <p>
                五险一金+补充公积金+补充医疗保险、餐补、团建基金、伯乐奖、带薪年假、节日福利、年度体检、各种零食饮料无限量供应......
              </p>
            </div>
          </li>
          <li class="mr">
            <div>
              <img src="../../assets/image/mobile/index/icon3.png" alt="">
            </div>
            <div class="content">
              <p>
                作为高新企业我们的同事可以享受应届生/海外留学生落户加分及居转户年限减免，优秀者可直接申请落户直通名额！
              </p>
            </div>
          </li>
          <li>
            <div>
              <img src="../../assets/image/mobile/index/icon4.png" alt="">
            </div>
            <div class="content">
              <p>
                如果你是喵星达人，上班即可遇见司宠“团团一家”，轻松实现撸猫自由！
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 轮播图 -->
    <van-swipe class="env" :autoplay="3000" indicator-color="white">
      <van-swipe-item
        class="program"
      ><img src="../../assets/image/mobile/index/pic1.png">
        <div class="des">
          <p class="title">开发工程师</p>
          <p class="myself">
            我非常喜欢现在的团队，有什么不同的技术实现方式都可以第一时间讨论，没什么顾虑。
          </p>
        </div>
      </van-swipe-item>
      <van-swipe-item
        class="program"
      ><img src="../../assets/image/mobile/index/pic2.png">
        <div class="des">
          <p class="title">美术设计师</p>
          <p class="myself">
            完全不担心技术提升的问题，团队内有大佬可以随时请教，内部比赛也挺多的，自我练习的同时还能集百家之长，何乐而不为？
          </p>
        </div>
      </van-swipe-item>
      <van-swipe-item
        class="program"
      ><img src="../../assets/image/mobile/index/gamedesign.jpg">
        <div class="des">
          <p class="title">游戏策划</p>
          <p class="myself">
            可以发挥自己的奇思妙想，也可以得到大佬们的指点，解锁游戏设计新思路!
          </p>
        </div>
      </van-swipe-item>
      <van-swipe-item
        class="program"
      ><img src="../../assets/image/mobile/index/gametest.jpg">
        <div class="des">
          <p class="title">游戏测试</p>
          <p class="myself">
            上班玩游戏，再也不会被长辈说不务正业啦，实现游戏自由的同时也找了适合自己的职业发展，特别是站着办公轻松解决久坐难题
          </p>
        </div>
      </van-swipe-item>
    </van-swipe>
    <div class="job">
      <span>招聘所有问题</span>
      <ul>
        <li>
          <p class="question">我能申请多个职位吗？</p>
          <p class="answer">
            不建议申请多个职位哦。非常高兴您是一位全能多面手，不过我们仍希望您仔细考虑自己的技能和兴趣所在，找到最合适自己的那一个。
          </p>
        </li>
        <li>
          <p class="question">
            我此前申请/面试过域起的职位，但未能通过。是否可以再次申请？
          </p>
          <p class="answer">
            当然可以。士别三日当刮目相待，只要您认为当前的能力和状态符合我们最新的岗位需求，我们非常欢迎您再次投递。
          </p>
        </li>
        <li>
          <p class="question">如何应聘？</p>
          <p class="answer">
            官网内选择相应岗位直接投递或发送简历至邮箱hr@gg.com（备注投递岗位）
          </p>
        </li>
      </ul>
      <div class="more">
        <span>联系我们：hr@gg.com</span>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '../modules/Head.vue'
import Foot from '../modules/Foot.vue'

export default {
  name: 'Recruit',
  components: { Head, Foot }
}
</script>

<style scoped>
.join-us {
   padding-top:3.575rem;
  width: 100%;
  /* height: 24.6875rem; */
  background: url('../../assets/image/mobile/index/zp.png') no-repeat;
  background-size: auto 24.6875rem;
  background-position: 0rem 3.575rem;
  font-weight: 700;
  color: #fdfefa;
  text-align: center;

}
.join-en {
  padding-top: 7rem;
  font-size: 3rem;
  line-height: 2.9375rem;
  opacity: 0.76;
  color: #fdfefa;
  font-family: Poppons !important;
}
.join-zh {
  font-size: 1.4375rem;
  margin-top: .5rem;
  color: #fdfefa;
  font-family: MicrosoftYaHei-Bold !important;
  font-weight: bold;
}
.post {
  width: 10.375rem;
  height: 2.25rem;
  font-size: .875rem;
  line-height: 2.25rem;
  margin: 2.75rem auto 0;
  cursor: pointer;
  font-weight: 400;
  color: #fdfefa;
  padding-bottom:10rem

}
.sociaty {
  width: 4.625rem;
  height: 2.25rem;
  background: #e60012;
  float: left;
  margin-left: 0.5rem;
  border-radius: 1.125rem 0 0 1.125rem;
}
.school {
  background-color: #82131c;
  width: 4.625rem;
  height: 2.25rem;
  float: left;
  border-radius: 0 1.125rem 1.125rem 0;
}
.post a {
  color: #fdfefa;
}
.introduction {
  margin-top: 2.8125rem;
  margin-bottom: 3.3125rem;
}
.introduction .why {
  text-align: center;
  color: #281826;
}
.why .title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.625rem;
  font-family: Microsoft YaHei;
  margin-bottom:.9375rem
}
.why .content {
  font-size: 0.8125rem;
  line-height: 1.25rem;
}
.details {
  display: flex;
  flex-wrap: wrap;
}
.details ul {
  margin-top: 0.9375rem;
  padding: 0 1rem;
  display: flex;
  flex-wrap: wrap;
}
.details li {
  width: 10rem;
  text-align: left;
  margin-top: 2.8125rem;
}
.details li img {
  width: 2.3rem;
}
.details .content {
  margin-left: 2.7rem;
  margin-top: -2.5rem;
}
.content p {
  margin: 0.2rem;
}
.details li p {
  font-size: .8125rem;
  color: #292826;
}
.mr {
  margin-right: 1.4rem;
}
.env {
  height: 24.5rem;
  background-color: rgb(228, 227, 227);
  margin-top: 3.125rem;
  display: flex;
  flex-wrap: wrap;
}
.env img {
  width: 21.9375rem;
  height: 11.625rem;
  margin: 1rem 0 0 0.75rem;
  display: block;
}
.program .des {
  width: 21.9375rem;
  height: 9.375rem;
  background-color: #fff;
  margin-left: 0.75rem;
  padding: 2rem 0 .8rem;
}
.program .des .title {
  font-size: 1.125rem;
  font-weight: 700;
  margin-bottom: .625rem;
  padding: 0 1.5625rem;
}
.myself {
  font-size: .9063rem;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #1e1f1f;
  line-height: 1.5625rem;
  padding: 0 1.5625rem;
}
.job {
  background-color: #fff;
  padding: 2rem 1.125rem;
}
.job span {
  display: block;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.625rem;
  color: #292826;
  text-align: center;
}
.job ul {
  margin-top: 1.875rem;
  font-size: 0.8125rem;
  line-height: 0.875rem;
  color: #292826;
}
.job ul li .question {
  font-size: 1.1rem;
  line-height: 1.0625rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  margin-top: 2rem;
  line-height: 1.25rem;
}
.job ul li .answer {
  line-height: 0.875rem;
  margin: .8rem 0;
  font-size: 0.8125rem;
  line-height: 1rem;
}
.job .more {
  width: 9.6rem;
  height: 1.625rem;
  border-radius: 0.8125rem;
  border: 1px solid rgba(41, 40, 38, 0.25);
  margin: 0 auto;
  cursor: pointer;
  margin-top: 2rem;
}
.more span {
  font-size: 0.8125rem;
  line-height: 1.5rem;
  font-weight: 400;
}</style>
<style>
.wrap .van-swipe__indicator--active {
  background: #060e2c !important;
}
.wrap .van-swipe__indicator {
  background: #fff;
  opacity: 1;
}
</style>
